
export const BRAND_NAME = 'Cric8';
export const BRAND_DOMAIN = 'cric8.io';
export const PROVIDER = 'BetFair';
export const PROVIDER_ID = 'BetFair';
export const BACKEND_DOMAIN = 'mnto9.com';


// export const BRAND_NAME = 'Hypexexch';
// export const BRAND_DOMAIN = 'hypexexch.com';
// export const PROVIDER = 'Betfair';
// export const PROVIDER_ID = 'Betfair';
